/*@font-face {*/
/*    font-family: "ONEMobilePOP", "GodoM";*/
/*    font-display: fallback;*/

/*    !*src: 사용할 폰트의 위치 및 형식*!*/
/*    !*   Safari, Android, iOS => ttf*!*/
/*    !*   Modern Browsers => woff*!*/
/*    !*   IE6-IE8 => eot*!*/

/*    src: url("./ONE-Mobile-POP.ttf") format("mobileFont"), url("./ONE-Mobile-POP-OTF.otf")*/
/*    format("mobileFont2");*/

@font-face {
    font-family: "black";
    src: url("./Pretendard-Black.otf");
}

@font-face {
    font-family: "light";
    src: url("./Pretendard-Light.otf");
}

@font-face {
    font-family: "bold";
    src: url("./Pretendard-Bold.otf");
}

@font-face {
    font-family: "regular";
    src: url("./Pretendard-Regular.otf");
}

@font-face {
    font-family: "semi";
    src: url("./Pretendard-SemiBold.otf");
}


/*!* 사용할 폰트의 기본 스타일 *!*/
/*font-weight: 400;*/
/*font-style: normal;*/